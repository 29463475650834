export const state = () => ({
	loading: false,
	languageStrings: {},
	newCodes: [], // ? this is used for stopping making new request of addLanguageStrings multiple times, check global.js you will see
	translationString: {},
	loadingStringsForTranslation: false,


	// cancel token
	stringCancelToken: {}
})

export const actions = {
	async languageStrings({ commit, state }, params) {
		commit('setStringCancelToken', { languageStrings: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		try {
			await this.$axios.get('/website-setting/language-strings', {
				params,
				cancelToken: state.stringCancelToken.languageStrings.token
			}).then((response) => {
				commit('setLanguageString', { data: [...response.data] })
			})
			commit('setLoading', false)
			return true;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async addLanguageStrings({ commit }, form) {
		commit('setLoading', true)
		try {
			const response = await this.$axios.post('/website-setting/language-strings', form)
				.then((response) => {
					if (response.status === 201) {
						return true;
					}
				})
				.catch(() => {
					return false
				})
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async updateLanguageStrings({ commit }, form) {
		const id = form.id;
		delete form.id;
		try {
			commit('setLoading', true)
			const response = await this.$axios.put(`/website-setting/language-strings/${id}`, form)
				.then((_) => {
					return true
				}).catch(() => { return false })
			commit('setLoading', false)
			return response;
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	async deleteLanguageStrings({ commit }, id) {
		try {
			commit('setLoading', true)
			const response = await this.$axios.delete(`/website-setting/language-strings/${id}`)
				.then((response) => {
					commit('setLoading', false)
					if (response.status === 200) {
						return true;
					} else {
						return false;
					}
				})
			return response
		} catch (error) {
			commit('setLoading', false)
			return false
		}
	},
	newCode({ commit }, code) {
		commit('newCode', code)
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguageString: (state, v) => {
		state.languageStrings = v
	},
	setPushLanguageString: (state, v) => {
		state.languageStrings.data.unshift(v)
	},
	newCode: (state, v) => {
		if (!state.newCodes.includes(v)) {
			state.newCodes = [...state.newCodes, v]
		}
	},
	loadingStringsForTranslation: (state, v) => {
		state.loadingStringsForTranslation = v
	},
	setStringCancelToken: (state, data) => {
		const oldData = { ...state.stringCancelToken }
		state.stringCancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	localeLanguage: state => language => {
		if (!language) {
			return {}
		}
		if (!state.languageStrings?.data?.length) return {}
		const translation = state.languageStrings.data.map((data) => {
			return {
				code: data.code,
				text: data.text[language]
			}
		})
		return translation;
	},
	storeTl: state => code => {
		if (state.translationString[code]) return state.translationString[code]
		return code
	}
}
